// overwrite confirm dialog
export function ConfirmationDialog(message) {
  // get the confirm dialog from DOM
  let dialog = document.querySelector("#confirm-modal")

  // replace the content of the message with a <p> element for each line in message
  dialog.querySelector("#confirm-message").innerHTML = ""
  message.split("\n").forEach((messageLine) => {
    dialog.querySelector("#confirm-message").innerHTML += ["<p>", messageLine,"</p>"].join("")
  })

  // Show dialog
  dialog.showModal()

  // Add the close event listener and promise true on confirm
  return new Promise((resolve, reject) => {
    dialog.addEventListener("close",() => {
      resolve(dialog.returnValue == "confirm")
    }, { once: true })
  })
}

window.confirm_enable_checkbox = async (checkbox, message) => {
  if( checkbox.checked ) {
    let result = await ConfirmationDialog(message)
    if( !result )
      checkbox.checked = false
  }
}

window.confirm_submit = async (message, run_function) => {
  let result = await ConfirmationDialog(message)
  if( result )
    run_function()
  return false;
}

window.confirm_and_spinner = (message, spinner_message = '') => {
  if(confirm(message)) { 
    full_page_spinner(spinner_message)
    return true
  } else {
    return false
  }
}

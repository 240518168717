window.hideNotes = function(notesId) {
  $('#edit-' + notesId).addClass("d-none")
  $('#show-' + notesId).removeClass("d-none")
  let notes = $('#edit-' + notesId +" #staff_cost_review_notes")
  notes.attr('required',false)
}

window.openNotes = function(notesId, selectedItem) {
  // check if selected item is other
  if( !selectedItem || selectedItem.selectedIndex + 1 == selectedItem.length) {
    $('#edit-' + notesId).removeClass("d-none")
    let notes = $('#edit-' + notesId +" #staff_cost_review_notes")
    notes.trigger("focus")
    notes.attr('required',true)
    notes[0].setSelectionRange(notes.val().length, notes.val().length)
    $('#show-' + notesId).addClass("d-none")
  } else {
    $('#edit-' + notesId).addClass("d-none")
    $('#show-' + notesId).addClass("d-none")
    console.log("submit")
    selectedItem.parentNode.requestSubmit()
    console.log("/submit")
  }
}

window.showComment = function() {
  $('#add-comment-btn').attr('disabled', true)
  $('.comment-opt').addClass('disabled')

  $('#new-comment').show()
  ScrollToId('new-comment')
  $('#new-comment #staff_cost_review_notes').trigger( "focus" )
}

window.dismissEmployeeComment = function() {
  $('#add-comment-btn').attr('disabled', false)
  $('.comment-opt').removeClass('disabled')
  $('#new-comment').hide()
  $('#new-comment #staff_cost_review_notes').val("")
  return false
}

window.editComment = function(commentId) {
  // disable comments options
  $('#add-comment-btn').attr('disabled', true)
  $('.comment-opt').addClass('disabled')

  $('#show-' + commentId).hide()
  $('#edit-' + commentId).removeClass('d-none')
  ScrollToId('edit-' + commentId)
  let notes = $('#edit-' + commentId+' #staff_cost_review_notes')
  notes.trigger( "focus" )
  notes[0].setSelectionRange(notes.val().length, notes.val().length)
}

window.dismissComment = function(commentId) {
  $('#edit-' + commentId).addClass('d-none')
  $('#show-' + commentId).show()

  // enable comments options
  $('#add-comment-btn').attr('disabled', false)
  $('.comment-opt').removeClass('disabled')
  return false
}
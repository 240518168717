window.set_datable_lang_es = function () {
  $.extend( true, $.fn.dataTable.defaults, {
    "language": {
      "decimal": ",",
      "thousands": ".",
      "info": "Mostrando del _START_ al _END_ de un total de _TOTAL_",
      "infoEmpty": "Mostrando del 0 al 0 de un total de 0",
      "infoPostFix": "",
      "infoFiltered": "(filtrado de un total de _MAX_)",
      "loadingRecords": "Cargando...",
      "lengthMenu": "_MENU_",
      "paginate": {
        "first": "Primero",
        "last": "Último",
        "next": "Siguiente",
        "previous": "Anterior"
      },
      "processing": "Procesando...",
      "search": "",
      "searchPlaceholder": "Buscar",
      "zeroRecords": "No se encontraron resultados",
      "emptyTable": "Ningún dato disponible en esta tabla",
      "aria": {
        "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
        "sortDescending": ": Activar para ordenar la columna de manera descendente"
      },
      //only works for built-in buttons, not for custom buttons
      "buttons": {
        "create": "Nuevo",
        "edit": "Cambiar",
        "remove": "Borrar",
        "copy": "Copiar",
        "csv": "fichero CSV",
        "excel": "tabla Excel",
        "pdf": "documento PDF",
        "print": "Imprimir",
        "colvis": "Visibilidad columnas",
        "collection": "Colección",
        "upload": "Seleccione fichero...."
      },
      "select": {
        "rows": {
          _: '%d filas seleccionadas',
          0: 'clic fila para seleccionar',
          1: 'una fila seleccionada'
        }
      }
    }
  } );
}

window.datable_run = function (id) {
  console.log("--> datatable_run(), id:", id);
  $(document).on('turbolinks:load', function() {
    console.log("turbo-links:load");
    "use strict";
    if ($('#' + id + '_wrapper').length == 0) {
      console.log('#' + id + '_wrapper found, proceed');
      // Translate to Spanish
      set_datable_lang_es();
      // Load plugin with some options enabled
      $('#' + id).DataTable({
        "paging": true,
        "lengthChange": true,
        "searching": true,
        "ordering": true,
        "info": true,
        "autoWidth": false,
        "responsive": true,
      });
    }
  });
}

window.datable_without_paging_run = function (id) {
  $(document).on('turbolinks:load', function() {
    "use strict";
    if ($('#' + id + '_wrapper').length == 0) {
      // Translate to Spanish
      set_datable_lang_es();
      // Load plugin with some options enabled
      $('#' + id).DataTable({
        "paging": false,
        "lengthChange": true,
        "searching": true,
        "ordering": true,
        "info": true,
        "autoWidth": false,
        "responsive": true,
      });
    }
  });
}

window.datable_scroll = function (id) {
  $(document).on('turbolinks:load', function() {
    "use strict";
    if ($('#' + id + '_wrapper').length == 0) {
      // Translate to Spanish
      set_datable_lang_es();
      // Load plugin with some options enabled
      $('#' + id).DataTable({
        bInfo: false,
        paging: false,
        scrollY: '380px',
        scrollCollapse: false,
        // lengthChange: true,
        // searching: true,
        // ordering: true,
        // info: false,
        // autoWidth: false,
        // responsive: true,
      });
    }
  });
}

window.admin_user_datatable = function () {
  $(document).on('turbolinks:load', function() {
    "use strict";
    if ($("#users-datatable_wrapper").length == 0) {
      set_datable_lang_es();
      $('#users-datatable').dataTable({
        "processing": true,
        "serverSide": true,
        "ajax": {
          "url": $('#users-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
          {"data": "id"},
          {"data": "user_code"},
          {"data": "name"},
          {"data": "email"},
          {"data": "office"},
          {"data": "root"},
          {"data": "sign_in_count"},
          {"data": "current_sign_in_ip"},
          {"data": "current_sign_in_at"},
          {"data": "updated_at"},
          {"data": "actions"},
        ]
      });
    }
  });
}
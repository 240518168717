(function($) {
  let Defaults = $.fn.select2.amd.require('select2/defaults');
  $.extend(Defaults.defaults, {
    searchInputPlaceholder: ''
  });
  let SearchDropdown = $.fn.select2.amd.require('select2/dropdown/search');
  let _renderSearchDropdown = SearchDropdown.prototype.render;
  SearchDropdown.prototype.render = function(decorated) {
    // invoke parent method
    let $rendered = _renderSearchDropdown.apply(this, Array.prototype.slice.apply(arguments));
    this.$search.attr('placeholder', this.options.get('searchInputPlaceholder'));
    return $rendered;
  };
})(window.jQuery);
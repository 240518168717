window.update_period_projections = (element, target, period_id, period_placeholder, no_period_placeholder) => {
  if( period_id ) { 
    // change placeholder and get the projections from the period
    target = target.split("period_id").join(period_id)
    $('#' + element).empty().select2({
      minimumResultsForSearch: Infinity,
      ajax: {
        url: target,
        dataType: 'json'
      },
      language: 'es',
      placeholder: period_placeholder,
      disabled: false
    });
  } else {
    // change placeholder and disable select when no period_id
    $('#' + element).empty().select2({
      placeholder: no_period_placeholder,
      searchInputPlaceholder: '🔎 Buscar...', 
      disabled: true
    });
  }
}

// Updates available accounts for each existing increment account selector
window.updateIncrementAccountSelectors = () => {
  // get all the selector from the increases div
  let selectors = $('#inject_increases .select2')

  selectors.each( (pos) => {
    // set the target selector
    let id = selectors[pos].id
    let targetSelector = $("#inject_increases #" + id )

    // store the current selector value
    let currentSelection = targetSelector.find(':selected')

    if( currentSelection.length > 0 )
      currentSelection = currentSelection[0].value
    else
      currentSelection = ""

    // remove current options
    targetSelector.empty().append( new Option(""))

    // update incremet selector
    updateIncrementAccountSelector(id, currentSelection)

    // remove unused selectors
    if( !targetSelector.val() ) {
      $('#inject_increases #increase-fields-' + id).remove()
      if( $('#inject_increases').children().length < 1 )
        $('#increases_header').addClass('d-none')

    }
  })
}


// Update available account in new increment account selector 
window.updateIncrementAccountSelector= (id, currentSelection = "") => {
  // only apply filter if one or more ledger accounts are selected
  let selectedLedgerAccounts = $('#ledger_account_ids').select2('data')

  // set the target selector
  let targetSelector = $('#inject_increases #'+id)

  if( selectedLedgerAccounts.length == 1 ) {
    targetSelector.append( new Option( selectedLedgerAccounts[0].text, selectedLedgerAccounts[0].id))

  } else if( selectedLedgerAccounts.length > 1) {
    selectedLedgerAccounts.forEach( (option) => {
      targetSelector.append( new Option( option.text, option.id))
    })

  } else {
    for( let i=0; i < $('#ledger_account_ids option').length; i++) {
      option = $('#ledger_account_ids option')[i]
      targetSelector.append( new Option( option.text, option.id))
    }
  }

  // set the current selection
  if( currentSelection )
    targetSelector.val(currentSelection)

  targetSelector.trigger('change')
}


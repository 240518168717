// js lib for budget line and details budget line form

// // Calculate the cost by months in the budget line form
window.calc_cost_by_months = function () {
  $('#budget_line_unit_cost,#units_month_1, #units_month_2, #units_month_3, #units_month_4, #units_month_5, #units_month_6, #units_month_7, #units_month_8, #units_month_9, #units_month_10, #units_month_11, #units_month_12').on('input', function () {
    let month_units = 0; // sum units by month
    let month_amounts = 0; // sum amount by month
    let unit_cost = $('#fixed_asset_unit_cost').val(); // cost by unit
    // Calculate total units
    for (let i = 1; i <= 12; i++) {
      month_units += Number($('#units_month_' + i).val());
    }
    // Set amount by months and update month_amounts
    for (let i = 1; i <= 12; i++) {
      let month_unit = $('#units_month_' + i).val();
      let amount_month = parseFloat((unit_cost * month_unit).toFixed(2));
      month_amounts += amount_month;
      $('#amount_month_' + i).text(numeral(amount_month).format());
    }
    // Update views and update units_number.
    $('#fixed_asset_units_number').val(month_units);
    $('#budget_line_total_units').text(month_units);
    $('#budget_line_total_amount').text(numeral(month_amounts).format());
    // Check if we can submit
    if (month_units > 0 && unit_cost > 0) {
      $('#submit-budget-line').removeClass('disabled');
      $('#submit-budget-line').attr('disabled', false);
    } else {
      $('#submit-budget-line').addClass('disabled');
      $('#submit-budget-line').attr('disabled', true);
    }
  });
}

// for budget line form and filters in budget line controller
window.update_asset_class = function (element, office_code, year, ledger_account_id) {
  $('#' + element).empty().select2({
    ajax: {
      url: `/${office_code}/${year}/budget_lines/asset_class_list?ledger_account_id=${ledger_account_id}`,
      // data: { authenticity_token: $('[name="csrf-token"]')[0].content },
      dataType: 'json'
    },
    language: 'es',
    searchInputPlaceholder: '🔎 Buscar...'
  });
}

// Change ledger account in Income budget line
window.inject_fields_for_projects = function (office_code, ledger_accounts_ids_with_projects, year) {
  $('#ledger-account-id-budget-line').on('select2:select', function (e) {
    let select2_data = e.params.data;
    // check if inject or not
    if (select2_data.id && ledger_accounts_ids_with_projects.includes(select2_data.id)) {
      full_page_spinner();

      // show the responsible area
      $('#responsible_area').removeClass("d-none")

      $.ajax({
        url: `/${office_code}/${year}/ingresos/inject_fields_for_projects`,
        data: {
          authenticity_token: $('[name="csrf-token"]')[0].content,
          ledger_account_id: encodeURIComponent(select2_data.id)
        },
        method: 'GET',
        success: function (res) {
          full_page_spinner_off();
        },
        error: function(res) {
          $('#project-budget-line').val("").trigger('change');
          showErrorToast("No se puede conectar con GesprOEI");
          full_page_spinner_off();
        }
        });
    } else {
      // hide responsible area
      if( !$('#responsible_area').hasClass("d-none") )
        $('#responsible_area').addClass("d-none")

      $("#fields-for-projects").html('');
      $("#project-info").html('');
    }

    $('#income_concept').focus()

  });
}

// Change funder in Income budget line
window.inject_project = function (office_code, year) {
  $('#funder-budget-line').on('select2:select', function (e) {
    $('#income_operation').val('0,00');
    let select2_data = e.params.data;
    full_page_spinner();
    $.ajax({
      url: `/${office_code}/${year}/ingresos/inject_field_projects`,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        funder: encodeURIComponent(select2_data.id)
      },
      method: 'GET',
      success: function (res) {
        full_page_spinner_off();
      },
      error: function(res) {
        $('#project-budget-line').val("").trigger('change');
        showErrorToast("No se puede conectar con GesprOEI");
        full_page_spinner_off();
      }
    });
  });
}

// Change project in Income and Cooperate budget line
window.inject_project_info = function (office_code, year) {
  $('#project-budget-line').on('select2:select', function (e) {
    let select2_data = e.params.data;

    // If no project is selected don't make the ajax call
    if(select2_data.id == "") {
      // Clean the project cost centers if present
      if( $('#cost-center-budget-line') ) {
        $('#cost-center-budget-line').val(0);
        $('#cost-center-budget-line').prop("disabled", true);;
        $('#cost-center-budget-line').trigger('change');
      }

      // Clean project info, if present
      if( $("#project-info") ) {
        $("#project-info").html('');
      }

      return;
    }

    full_page_spinner();
    $.ajax({
      url: `/${office_code}/${year}/ingresos/inject_project`,
      data: {
        authenticity_token: $('[name="csrf-token"]')[0].content,
        gesproei_project_id: encodeURIComponent(select2_data.id)
      },
      method: 'GET',
      success: function (res) {
        full_page_spinner_off();
      },
      error: function(res) {
        $('#project-budget-line').val("").trigger('change');
        showErrorToast("No se puede conectar con GesprOEI");
        full_page_spinner_off();
      }
    });
  });
  // add validation for operation (0-100)
  $(function () {
    $("#budget-line-form").validate(
      {
        rules:
          {
            "income[operation]":
              {
                range: [0, 100]
              },
          }
      });
  });
}

/////////////////////////// CALCULATE BY AMOUNT
// Calculate the cost by months amount in the budget line form
window.calc_cost_by_months_amount = function (kind_budget_line) {
  // Change unit_cost involves spread cost over months
  $(`#${kind_budget_line}_unit_cost, #${kind_budget_line}_units_number `).on('input', function () {
    let unit_cost = parseFloat($(`#${kind_budget_line}_unit_cost`).val()); // cost by unit
    let units_number = parseFloat($(`#${kind_budget_line}_units_number`).val()); // number units
    let spread_amount_on_months = parseFloat(((unit_cost * units_number) / 12).toFixed(2));
    let elevent_months_cost = 0;
    // Paint total amount main
    $('#budget_line_total_amount_main').text(numeral(unit_cost * units_number).format());
    for (let i = 1; i <= 11; i++) {
      elevent_months_cost += spread_amount_on_months;
      $(`#cost_month_${i}`).val(spread_amount_on_months.toFixed(2));
    }
    $('#cost_month_12').val(((unit_cost * units_number) - elevent_months_cost).toFixed(2));
    // Insert the sum of the total amount distributed
    let total_spread = 0;
    for (let i = 1; i <= 12; i++) {
      total_spread += parseFloat(($(`#cost_month_${i}`).val()));
    }
    total_spread = parseFloat(total_spread.toFixed(2));
    $('#budget_line_total_amount').text(numeral(total_spread).format());
    // Set all month to ckeck
    for (let i = 1; i <= 12; i++) {
      $(`#cast_month_${i}`)[0].checked = true;
    }
    // Check if we can submit
    if (total_spread == (unit_cost * units_number).toFixed(2) && unit_cost > 0 && units_number > 0) {
      $('#submit-budget-line').removeClass('disabled');
      $('#submit-budget-line').attr('disabled', false);
      $('#budget_line_total_amount').removeClass('badge-danger');
      $('#budget_line_total_amount').addClass('badge-success');
    } else {
      $('#submit-budget-line').addClass('disabled');
      $('#submit-budget-line').attr('disabled', true);
      $('#budget_line_total_amount').removeClass('badge-success');
      $('#budget_line_total_amount').addClass('badge-danger');
    }
  });
  // Check if the sum of cost_month is equal to unit_cost
  $(`#cost_month_1, #cost_month_2, #cost_month_3, #cost_month_4, #cost_month_5, #cost_month_6, #cost_month_7, #cost_month_8, #cost_month_9, #cost_month_10, #cost_month_11, #cost_month_12`).on('input', function () {
    let unit_cost = parseFloat($(`#${kind_budget_line}_unit_cost`).val()); // cost by unit
    let units_number = parseFloat($(`#${kind_budget_line}_units_number`).val()); // number units
    let total_spread = 0;
    for (let i = 1; i <= 12; i++) {
      total_spread += parseFloat(($(`#cost_month_${i}`).val()));
    }
    total_spread = parseFloat(total_spread.toFixed(2));
    $('#budget_line_total_amount').text(numeral(total_spread).format());
    // Adjust ckeckboxs with no amount 0
    for (let i = 1; i <= 12; i++) {
      let month_amount = parseFloat($(`#cost_month_${i}`).val()); // number units
      if (month_amount == 0) {
        $(`#cast_month_${i}`)[0].checked = false;
      }
      if (month_amount > 0) {
        $(`#cast_month_${i}`)[0].checked = true;
      }
    }
    // Check if we can submit
    if (total_spread == (unit_cost * units_number).toFixed(2) && (unit_cost * units_number) > 0) {
      $('#submit-budget-line').removeClass('disabled');
      $('#submit-budget-line').attr('disabled', false);
      $('#budget_line_total_amount').removeClass('badge-danger');
      $('#budget_line_total_amount').addClass('badge-success');
    } else {
      $('#submit-budget-line').addClass('disabled');
      $('#submit-budget-line').attr('disabled', true);
      $('#budget_line_total_amount').removeClass('badge-success');
      $('#budget_line_total_amount').addClass('badge-danger');
    }
  });
}

// Set amount cost to 0
window.allocate_months_amount_to_zero = function () {
  for (let i = 1; i <= 12; i++) {
    $(`#cost_month_${i}`).val('0,00');
    $(`#cast_month_${i}`)[0].checked = false;
  }
  $('#budget_line_total_amount').text('0,00');
  $('#submit-budget-line').addClass('disabled');
  $('#submit-budget-line').attr('disabled', true);
  $('#budget_line_total_amount').removeClass('badge-success');
  $('#budget_line_total_amount').addClass('badge-danger');
}


// Check if some checkbox has been pressed and recalculate
window.calculate_by_month_pressed = function (kind_budget_line) {
  let unit_cost = parseFloat($(`#${kind_budget_line}_unit_cost`).val()); // cost by unit
  let units_number = parseFloat($(`#${kind_budget_line}_units_number`).val()); // number units
  let total_amount = (unit_cost * units_number).toFixed(2);
  let month_checked = [];
  // Months checked
  for (let i = 1; i <= 12; i++) {
    if ($(`#cast_month_${i}`)[0].checked) {
      month_checked.push(i)
    }
  }
  let spread_amount_on_months = parseFloat(((total_amount) / month_checked.length).toFixed(2));
  // Set all month amount to zero
  for (let i = 1; i <= 12; i++) {
    $(`#cost_month_${i}`).val('0.00');
  }
  // Spread amount on months
  if (spread_amount_on_months == total_amount) {
    $(`#cost_month_${month_checked[0]}`).val(parseFloat(total_amount));
  } else {
    let months_cost = 0
    for (let i = 0; i <= (month_checked.length - 2); i++) {
      months_cost += spread_amount_on_months;
      $(`#cost_month_${month_checked[i]}`).val(spread_amount_on_months.toFixed(2));
    }
    $(`#cost_month_${month_checked[month_checked.length - 1]}`).val(parseFloat((total_amount - months_cost).toFixed(2)));
  }
  // Check if the sum of cost_month is equal to total_amount
  let total_spread = 0;
  for (let i = 1; i <= 12; i++) {
    total_spread += parseFloat(($(`#cost_month_${i}`).val()));
  }
  total_spread = parseFloat(total_spread.toFixed(2));
  $('#budget_line_total_amount').text(numeral(total_spread).format());
  // Check if we can submit
  if (total_spread == total_amount) {
    $('#submit-budget-line').removeClass('disabled');
    $('#submit-budget-line').attr('disabled', false);
    $('#budget_line_total_amount').removeClass('badge-danger');
    $('#budget_line_total_amount').addClass('badge-success');
  } else {
    $('#submit-budget-line').addClass('disabled');
    $('#submit-budget-line').attr('disabled', true);
    $('#budget_line_total_amount').removeClass('badge-success');
    $('#budget_line_total_amount').addClass('badge-danger');
  }
}

// Projections calculator
window.projection_calculator = function () {
  $('#details_budget_line').on('input', function(event) {
    // Update tracking table for currency
    let idDetailsBudgetLine = $(event.target).attr('id').split('_')[2];
    let tracking_projection_backup
    let projection_backup = Number($(`#backup_projection_${idDetailsBudgetLine}`).text());
    let projection = Number($(event.target).val());
    let new_tracking_projection
    let exchange_rate_usd

    // Obtain the exchange rate for the month for 1 USD
    exchange_rate = $('#exchange-rate-month-' + idDetailsBudgetLine).text();
    exchange_rate = exchange_rate.split(".").join("");
    exchange_rate = exchange_rate.split(",").join(".");
    // convert exchange rate to float
    exchange_rate = parseFloat( exchange_rate );


    if ($('#budget-currency-code').text() == 'USD') {
      tracking_projection_backup = Number($('#executions_projections_tracking #backup_usd_' + idDetailsBudgetLine + '_2').text());
      new_tracking_projection = (tracking_projection_backup - projection_backup) + projection;
      $('#executions_projections_tracking #' + idDetailsBudgetLine + '_2').text(numeral(new_tracking_projection).format()).trigger('change');
      // Update tracking table for local
      new_tracking_projection = new_tracking_projection * exchange_rate;
      $('#executions_projections_tracking #' + idDetailsBudgetLine + '_1').text(numeral(new_tracking_projection).format()).trigger('change');

    } else {
      tracking_projection_backup = Number($('#executions_projections_tracking #backup_local_' + idDetailsBudgetLine + '_1').text());
      new_tracking_projection = (tracking_projection_backup - projection_backup) + projection;
      $('#executions_projections_tracking #' + idDetailsBudgetLine + '_1').text(numeral(new_tracking_projection).format()).trigger('change');
      // --- Update tracking table for usd ---
      new_tracking_projection = new_tracking_projection / exchange_rate;
      $('#executions_projections_tracking #' + idDetailsBudgetLine + '_2').text(numeral(new_tracking_projection).format()).trigger('change');
    }

    // Calculate total tracking local currency
    let total_tracking_local = 0;
    for (let i = 1; i <= 12; i++) {
      total_tracking_local += numeral($('#executions_projections_tracking #' + i + '_1').text()).value();
    }
    // Calculate total tracking usd
    $('#total_tracking_1').text(numeral(total_tracking_local).format());
    let total_tracking_usd = 0;
    for (let i = 1; i <= 12; i++) {
      total_tracking_usd += numeral($('#executions_projections_tracking #' + i + '_2').text()).value();
    }
    $('#total_tracking_2').text(numeral(total_tracking_usd).format());

    // Calculate desviation
    if ($('#budget-currency-code').text() == 'USD') {
      // Calculate desviation usd currency
      let total_budget_group_usd = Number($('#total_budget_group_usd').text());
      let desviation_usd = total_tracking_usd - total_budget_group_usd;
      $('#desviation_usd').text(numeral(desviation_usd).format());

      // Calculate desviation usd local
      let total_budget_group_local = Number($('#total_budget_group_local').text());
      let desviation_local = desviation_usd * exchange_rate_usd;
      $('#desviation_local').text(numeral(desviation_local).format());

      // Calculate desviation percent (same for both)
      let desviation_percent_local =  (desviation_local / total_budget_group_local) * 100.0;
      $('#desviation_percent_local').text(numeral(desviation_percent_local).format()+'%');
      $('#desviation_percent_usd').text(numeral(desviation_percent_local).format()+'%');

    } else {
      // Calculate desviation local currency
      let total_budget_group_local = Number($('#total_budget_group_local').text());
      let desviation_local = total_tracking_local - total_budget_group_local;
      $('#desviation_local').text(numeral(desviation_local).format());

      // Calculate desviation local usd
      let total_budget_group_usd = Number($('#total_budget_group_usd').text());
      let desviation_usd = desviation_local / exchange_rate_usd;
      $('#desviation_usd').text(numeral(desviation_usd).format());

      // Calculate desviation percent (same for both)
      let desviation_percent_local =  (desviation_local / total_budget_group_local) * 100.0;
      $('#desviation_percent_local').text(numeral(desviation_percent_local).format()+'%');
      $('#desviation_percent_usd').text(numeral(desviation_percent_local).format()+'%');
    }
  })
}

// Keep updating totals cells
window.projection_totals = function() {
  let accumulate_sum = function(accumulator, accumulated) {
    let sum = 0.0;
    accumulated.each(function() {
      let val = parseFloat(this.value);
      if (this.value.includes(','))
        val = parseFloat(this.value.replace('.','').replace(',','.'));
      if (this.value) sum += val;
    });
    accumulator.val(numeral(sum).format()).change();
  }

  $('.current-projection-month-oei').on('change', function() {
    accumulate_sum($('#current_projection_total_oei'), $('.current-projection-month-oei'));
  });

  $('.current-projection-month-no-oei').on('change', function() {
    accumulate_sum($('#current_projection_total_no_oei'), $('.current-projection-month-no-oei'));
  });
}

// Keep tooltips updated
window.tooltips_updated = function() {
  $('[data-toggle="tooltip"]').on('change', function() {
    let val = $(this).children("input").first().val();
    if (val === undefined) 
      val = $(this).text();
    $(this).attr("data-original-title", val);
    $(this).tooltip('hide');
  });
}
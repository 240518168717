// set focus to a form element on modal and no modal views
window.set_focus = (id) => {
  if($('.modal').length != 0) {
    $('.modal').on('shown.bs.modal', () => {
      $('#' + id).focus();
    })
  } else {
    console.log("set focus to", id)

    $('document').ready( () => {
      $('#' + id).focus();
    })
  }
}